import { collection, getDocs, orderBy, query, Timestamp, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { dbChatBot } from "../utils/firebase/config";

const PrivateRoute = ({ Component }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true); // Added loading state
  const loc = useLocation();

  const checkFirebaseSubscription = async (username) => {
    if (!username) {
      return;
    }

    try {
      const docRef = collection(dbChatBot, "payments");

      // Fetch data sorted by paymentDate (latest to oldest)
      const q = query(docRef, where("userId", "==", username), orderBy("paymentDate", "desc"));

      const querySnapshot = await getDocs(q);

      // If documents are found, check the subscription status
      if (!querySnapshot.empty) {
        const subscriptionData = querySnapshot.docs.map((doc) => doc.data());

        // Grab the most recent subscription object
        const latestSubscription = subscriptionData[0];
        console.log(latestSubscription);
        let expiryDate;
        if (latestSubscription.expiryDate instanceof Timestamp) {
          // If expiryDate is a Firestore Timestamp
          expiryDate = latestSubscription.expiryDate.toDate();
        } else if (typeof latestSubscription.expiryDate === "object" && latestSubscription.expiryDate.seconds) {
          // If expiryDate is in { seconds, nanoseconds } format
          expiryDate = new Date(latestSubscription.expiryDate.seconds * 1000);
        } else {
          // Handle the case where expiryDate is not valid
          console.error("Invalid expiryDate format");
          return { subscribed: false, username }; // Return subscribed false
        }

        const currentDate = new Date();
        console.log(expiryDate);
        // Check if the subscription is expired
        if (currentDate > expiryDate) {
          console.log("Subscription expired");
          return { subscribed: false, username }; // Return username with subscribed false
        } else {
          console.log("Subscription is active");
          return { subscribed: true, username, subscriptionData: latestSubscription , expiryDate }; // Return active subscription data
        }
      } else {
        console.log("No payment data found");
        return { subscribed: false, username }; // No payment data found, return subscribed false
      }
    } catch (error) {
      console.error("Error checking subscription: ", error);
      return { subscribed: false, username }; // Return subscribed false on error
    }
  };

  const browserPathName = window.location.pathname;

  useEffect(() => {
    async function fetch() {
      let username = auth?.user?.username;
      const subscription = await checkFirebaseSubscription(username);
      if (subscription) {
        console.log(subscription);
        if (!subscription.subscribed) {
          console.log("HERE AND NOT SUBSCRIBED");
          navigate("/settings");
        }
        localStorage.setItem(
          "new_sub",
          JSON.stringify({
            subscribed: subscription.subscribed,
            username: subscription.username,
            expiryDate:  subscription.expiryDate ?? '',
            packageName:subscription?.subscriptionData?.subscriptionPlan?.titleEn ?? ''
          })
        );
      }
      setLoading(false); // Set loading to false once the subscription check is complete
    }
    fetch();
  }, [browserPathName]);

  // Check if the user is authenticated
  if (!auth.isAuth) {
    return <Navigate to="/" replace={true} />;
  }

  // Show loading spinner while checking subscription
  if (loading) {
    return (
      <Spin
        size="large"
        style={{ justifyContent: "center", marginTop: "25%", display: "flex" }}
      />
    );
  }

  return <Component />;
};

export default React.memo(PrivateRoute);
